:root {
	--white: #fff;
	--orange: #e6421e;
	--black: #000;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	outline: 0;
}

@media (max-width: 1080px) {
	html {
		font-size: 93.75%; //15px
	}
}

@media (max-width: 720px) {
	html {
		font-size: 87.5%; //14px
	}
}

html {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	font-family: "Roboto", sans-serif;
	font-size: 16px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

ol,
ul {
	list-style: none;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;

	& > main {
		flex: auto;
	}
}
.btn {
	padding: 0.375rem 0.75rem;
	display: inline-block;
	vertical-align: middle;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	user-select: none;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 400;
	color: var(--black);
	cursor: pointer;
}
