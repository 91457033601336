.contrast {
	background-color: var(--black);

	.header__wrap,
	.main__wrap,
	.footer__wrap {
		background-color: var(--black);
		border: 0;
		box-shadow: none;
		color: var(--white);

		hr {
			opacity: 1;
		}
	}
}
