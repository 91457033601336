.list-menu {
	&__block {
		& + .list-menu__block {
			margin-top: 3rem;
			padding-top: 3rem;
			border-top: 1px solid #ccc;
		}

		ul {
			&.delicatessen {
				@media (min-width: 768px) {
					max-width: 70%;
				}
				& li {
					display: flex;
					align-items: baseline;

					.line {
						flex: 1;
						margin: 0 1rem;
						border-bottom: 2px dotted #999;
					}
				}
			}

			& li {
				& h2 {
					margin-bottom: 0.5rem;

					small {
						padding-left: 1rem;
						display: inline-block;
					}
				}

				& p {
					margin-bottom: 1rem;
				}
			}
		}
	}

	&__delicatessen {
		h2 {
			margin-bottom: 0.5rem;
			font-weight: 600;

			& + ul {
				margin-bottom: 2rem;
			}
		}

		& ul {
			& li {
				margin-bottom: 1rem;

				p {
					margin-bottom: 0;
				}
			}
		}
	}
}
