.header {
	&__wrap {
		margin-bottom: 2rem;
		padding: 1.5rem 0;
		background-color: var(--orange);
		box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
		border-bottom: 1px solid var(--white);
		font-size: 2rem;
		font-weight: 500;
		color: var(--white);
	}

	&-logo {
		@media (max-width: 576px) {
			text-align: center;
			margin-bottom: 1.5rem;

			img {
				max-width: 10rem;
			}
		}

		@media (min-width: 576px) {
			img {
				max-width: 15rem;
			}
		}
	}

	&-accessibility {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		text-align: right;

		p {
			margin-bottom: 0.3rem;
			font-size: 0.8rem;
		}

		ul {
			display: flex;

			li {
				& + li {
					margin-left: 0.5rem;
				}
				button {
					&.btn {
						width: 3rem;
						border-color: var(--white);
						text-align: center;
						font-weight: 700;
						color: var(--white);

						&-contrast {
							&.rotate {
								i {
									transform: rotate(-180deg);
								}
							}
						}
					}
				}
			}
		}
	}
}

.footer {
	&__wrap {
		margin-top: 2rem;
		padding: 3rem 0;
		background-color: rgba(0, 0, 0, 0.05);
		border-top: 1px solid var(--white);
		box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
		text-align: justify;
		//text-align: center;
		font-size: 0.8rem;

		hr {
			margin: 2rem 0;
			opacity: 0.4;
		}
	}

	&-law {
		& h2 {
			margin-bottom: 0.3rem;
			text-transform: uppercase;
			font-size: 0.8rem;
			font-weight: 600;
		}

		& h3 {
			margin-bottom: 0.8rem;
			text-transform: uppercase;
			font-size: 0.9rem;
			font-weight: 600;
		}

		& p {
			& + p {
				margin-top: 1rem;
			}

			text-align: justify;
		}

		& ul {
			li {
				margin-top: 0.5rem;
			}
		}
	}

	&-copyright {
		display: flex;

		@media (min-width: 768px) {
			justify-content: space-between;
		}

		@media (max-width: 768px) {
			flex-direction: column;
			text-align: center;

			& p + p {
				margin-top: 0.5rem;
			}
		}
	}
}
